import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BlogPost from "../components/blogPost";

const uri = "/about/";

const TermsAndConditionsPage = ({ location }) => {
  const searchQuery = location.state?.searchQuery;

  return (
    <Layout pageInfo={{ pageName: "private-policy", searchQuery: searchQuery }}>
      <Seo title="Terms and Conditions" keywords={[`privacy`, `policy`]} description="Terms and Conditions" url={uri} />
      <Container fluid>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Terms and Conditions</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <BlogPost title="Terms and Conditions" adCount={0}>
          <Container fluid>
            <Row className="d-flex flex-wrap">
              <p>
                Welcome to ILoveItCamping.com (the "Website"). Please read this Terms and
                Conditions of Use Notice ("Notice") carefully before using the Website. By viewing or otherwise using
                this Website, you agree to the terms and conditions in this Notice.
              </p>

              <p>
                We reserve the right in our sole discretion to change, modify, add or delete portions of this Notice at
                any time. We will provide notice of such changes only by posting the updated notice on our Website and
                changing the "last updated" date listed above. This Notice applies exclusively to your access to, and
                use of, this Website and does not alter in any way the terms or conditions of any other agreement you
                may have with ILoveItCamping.com for products, services or otherwise. We encourage you
                to review our Notice each time you visit our Site to see if it has been updated since your last visit.
              </p>
              <h3>Privacy Policy</h3>

              <p>
              ILoveItCamping.com believes strongly in protecting user privacy. ILoveItCamping.com has therefore adopted a Privacy
                Policy. Users of this Website should refer to ILoveItCamping.com’s Privacy Policy, available for viewing{" "}
                <Link to="/privacy-policy">here</Link>, before providing any information.
              </p>
              <h3>Proprietary Rights</h3>

              <h3>I. Copyrights</h3>
              <p>
                All Website materials, including, without limitation, the ILoveItCamping.com logo, design, text,
                graphics, other files, and the selection and arrangement thereof (collectively, "Content") are either
                ALL RIGHTS RESERVED Copyright © 2022 ILoveItCamping.com or are the proprietary property of ILoveItCamping.com
                affiliates or licensors. You may electronically copy and print to hard copy portions of this Website for
                the sole purpose of using materials it contains for informational and non-commercial, personal use only,
                provided that you keep all copyright or other proprietary notices intact. Any other use of Content,
                including any commercial use, reproduction for purposes other than described above, modification,
                distribution, republication, display, or performance, without the prior written permission of ILoveItCamping.com
                is strictly prohibited.
              </p>

              <h3>II. Trademarks</h3>
              <p>
                "IloveItCamping.com" and "I Love It Camping" and all other trademarks on this Website are either trademarks
                or registered trademarks of ILoveItCamping.com and its licensors, and may not be copied, imitated or used, in
                whole or in part, without the prior written permission of ILoveItCamping.com. In addition, all page headers,
                custom graphics, button icons, and scripts are service marks, trademarks, and/or trade dress of
                ILoveItCamping.com, and may not be copied, imitated, or used, in whole or in part, without the prior
                written permission of ILoveItCamping.com. All other trademarks, registered trademarks, product names and
                company names or logos mentioned on the Website are the property of their respective owners.
              </p>

              <p>
              ILoveItCamping.com may have other patents, patent applications, trademarks, copyrights, or other intellectual
                property rights covering subject matter in any web pages, and any software that are part of the Website.
                Unless we have granted you licenses to our intellectual property in this Notice, our providing you with
                such web pages or any software does not give you any license to our intellectual property. Any rights
                not expressly granted herein are reserved.
              </p>

              <h3>Frames</h3>
              <p>
                You may not use, frame or utilize framing techniques to enclose any ILoveItCamping.com trademark, logo or
                other proprietary information, including the images found at this Website, the content of any text or
                the layout/design of any page or form contained on any page of the Website, without ILoveItCamping.com’s
                express written consent. Except as noted above, you are not conveyed any right or license, by
                implication, estoppel or otherwise, in or under any patent, trademark, copyright or proprietary right of
                ILoveItCamping.com or any third party.
              </p>

              <h3>Third-Party Content & Services</h3>
              <p>
                ILoveItCamping.com may provide hyperlinks to sites and content of third parties ("Third-Party Content")
                as a service to those interested in this information. ILoveItCamping.com does not monitor nor does ILoveItCamping.com
                have control over any Third-Party Content. ILoveItCamping.com does not endorse or adopt any Third-Party
                Content and can make no guarantee as to its accuracy or completeness. ILoveItCamping.com does not warrant the
                accuracy of any information contained on, and undertakes no responsibility to update or review, any
                Third-Party Content. Users use these hyperlinks and Third Party-Content contained therein at their own
                risk.
              </p>

              <h3>Submissions</h3>
              <p>
                By submitting, disclosing or offering any recipe, review, photograph, image, "Saved Recipes" list,
                comments, feedback, postcards, suggestions, ideas, notes, drawings, concepts and other information,
                content or material, or other item (each, a "Submitted Item") to ILoveItCamping.com, either online or
                offline and whether or not solicited by ILoveItCamping.com, you hereby grant to ILoveItCamping.com an
                irrevocable, nonexclusive, perpetual, worldwide, royalty-free right and license to use, display,
                publicly perform, modify, reproduce, publish, distribute, make derivative works of, sublicense and
                otherwise commercially and non-commercially exploit your Submitted Items and all copyright, trade
                secret, trademark or other intellectual property rights therein, in any manner or medium now existing or
                hereafter developed (including but not limited to print, film or electronic storage devices), without
                compensation of any kind to you or any third party.
              </p>

              <p>
                You hereby represent and warrant that (a) you have all necessary right, power and authority to grant the
                license set forth herein to your Submitted Item, and (b) your Submitted Item does not violate,
                misappropriate or infringe any copyright, trade secret, trademark or other intellectual property right
                of any third party. You will take, at your expense, any further action (including, without limitation,
                execution of affidavits and other documents) reasonably requested by ILoveItCamping.com to effect, perfect and
                confirm the license granted to ILoveItCamping.com to your Submitted Item as set forth herein.
              </p>

              <p>
                Publication or use of any Submitted Items is at the sole discretion of ILoveItCamping.com and ILoveItCamping.com is
                under no obligation to publish or use any Submitted Item. If your Submitted Item is published, used
                and/or posted on the Website or otherwise used by ILoveItCamping.com, we will include your name in conjunction
                with such publication, posting or use. By submitting, disclosing or offering a Submitted Item, you
                hereby grant ILoveItCamping.com the right to use your name in connection with the publication, use or posting
                of your Submitted Item. You must include your full name and email address with your Submitted Item so
                that we can contact you if we have any questions about your Submitted Item; however, only your name and
                not your email address will be published with your Submitted Item.
              </p>

              <h3>User Conduct</h3>

              <p>
                This Website may include discussion forums or other interactive areas, including, but not limited to,
                bulletin boards and recipe storage and exchange areas. All such forums and interactive areas shall be
                used only for non-commercial purposes. You are solely liable for any material you upload or transmit to
                the discussion forums or interactive areas of this Website. You agree not to use the Website to do any
                of the following:
              </p>

              <ul>
                <ol>
                  <li>
                    Upload, post, email, transmit, distribute or otherwise publish any message, information, text or
                    other material ("Material") that we deem to be unlawful, harmful, libelous, tortious, defamatory,
                    obscene, vulgar, pornographic, indecent, lewd, harassing, threatening, invasive of privacy or
                    publicity rights, abusive, inflammatory, hateful, or racially, ethnically or otherwise
                    objectionable;
                  </li>
                  <li>
                    Upload or transmit any Material that would constitute or encourage a criminal offense, violate the
                    rights of any party, or that would otherwise create liability or violate any local, state, national
                    or international law, including, without limitation, the regulations of the U.S. Securities and
                    Exchange Commission, any rules of any securities exchange such as the New York Stock Exchange, the
                    American Stock Exchange, or the NASDAQ;
                  </li>
                  <li>
                    Upload, email, transmit or otherwise make available any Material that may infringe any patent,
                    trademark, trade secret, copyright or other intellectual or proprietary right of any party;
                  </li>
                  <li>
                    Impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity;
                  </li>
                  <li>
                    Without ILoveItCamping.com’s written permission, distribute, publish, or make available any unsolicited or
                    unauthorized promotions, advertising or solicitations for funds, goods or services, including junk
                    mail, spam and chain letters;
                  </li>
                  <li>Harm minors in any way; order</li>
                  <li>
                    Upload, post, email, transmit or otherwise make available any Material that contains software
                    viruses or any other computer code, files or programs designed to interrupt, destroy or limit the
                    functionality of any computer software or hardware or telecommunications equipment.
                  </li>
                </ol>
              </ul>

              <h3>Disclaimer</h3>
              <p>
                THIS WEBSITE AND THE CONTENT AVAILABLE ON IT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT
                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND YOU AGREE THAT USE OF THIS WEBSITE AND/OR ITS
                CONTENT IS AT YOUR SOLE RISK. ILoveItCamping.com DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
                NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NON-INFRINGEMENT. ILoveItCamping.com DOES NOT REPRESENT OR WARRANT THAT MATERIALS ON THIS WEBSITE ARE
                ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE. ILoveItCamping.com DOES NOT REPRESENT OR WARRANT THAT
                THIS WEBSITE OR ITS SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE THAT YOUR
                USE OF THE WEBSITE AND CONTENT IS AT YOUR SOLE RISK.
              </p>

              <p>
              ILoveItCamping.com reserves the right to change any and all Content contained on this Website at any time
                without notice. Reference to any products, services, processes or other information, by trade name,
                trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or
                recommendation thereof by ILoveItCamping.com.
              </p>

              <h3>Limitation of Liability</h3>

              <p>
                IN NO EVENT SHALL ILoveItCamping.com OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR SUPPLIERS BE
                LIABLE FOR ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF
                ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN
                ACTION IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN
                ANY WAY CONNECTED WITH THE USE OF, OR INABILITY TO USE, THIS WEBSITE OR CONTENT CONTAINED ON, OR
                ACCESSED THROUGH, THIS WEBSITE, EVEN IF ILoveItCamping.com HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. Some jurisdictions do not allow the limitation or exclusion of liability for incidental or
                consequential damages, so some of the above may not apply to you.
              </p>

              <h3>Indemnification</h3>
              <p>
                You agree to defend, indemnify and hold harmless ILoveItCamping.com and its affiliates, officers, directors,
                agents, suppliers and licensors from and against any and all claims, damages, costs and expenses,
                including attorneys’ fees, arising from or related to your use of the Website or any Materials or
                Submissions you provide, including, but not limited to, any claim by a third party that any Materials or
                Submissions infringe or violate such third party’s rights or interests.
              </p>

              <h3>Removal and Disclosure</h3>

              <p>
                We reserve the right to, at our sole discretion, remove, take down, destroy or delete any Material and
                Submissions at any time and for any reason, including, but not limited to Material and Submissions, that
                we deem inappropriate or which we believe may subject us to any liability. We may access, use and
                disclose transaction information about your use of our Website, and any Material and Submissions
                transmitted by you via or in connection with our Website, to the extent permitted by law, in order to
                comply with the law (e.g., a lawful subpoena); to initiate, render, bill and collect for our products
                and services; to protect our rights or property, or to protect users of our Website from fraudulent,
                abusive, or unlawful use of our Website. INDIRECT OR ATTEMPTED VIOLATIONS OF THIS NOTICE OR ANY RELATED
                POLICY, GUIDELINE OR AGREEMENT, AND ACTUAL OR ATTEMPTED VIOLATIONS BY A THIRD PARTY ON YOUR BEHALF,
                SHALL BE CONSIDERED VIOLATIONS OF THIS AGREEMENT BY YOU.
              </p>

              <h3>Applicable Law</h3>
              <p>
                These terms and conditions shall be governed by and construed in accordance with the laws of the State
                of Minnesota, without regard to its choice of law principles to the contrary. You agree that any action
                at law or in equity arising out of or relating to these terms and conditions shall be filed only in the
                state and federal courts residing in Ramsey County, Minnesota, and you hereby irrevocably and
                unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or
                proceeding arising out of this Notice.
              </p>

              <h3>Termination</h3>
              <p>
              ILoveItCamping.com reserves the right, without notice and in its sole discretion at any time, to terminate
                your license to use this Website, to block or prevent future access to and use of this Website, and to
                remove and discard any Material and Submissions.
              </p>

              <h3>Severability</h3>
              <p>
                If any provision of this Notice shall be deemed unlawful, void, or for any reason unenforceable, then
                that provision shall be deemed severable from this Notice and shall not affect the validity and
                enforceability of any remaining provisions.
              </p>
              <h3>Copyright Complaints</h3>
              <p>
                Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright
                infringement must be sent to Service Provider’s Designated Agent.
              </p>

              <p>For the Designated Agents information please contact us at info@iloveitcamping.com</p>

              <p>
                We may give notice of a claim of copyright infringement to our users by means of a general notice on the
                Website, electronic mail to a user’s e-mail address in our records, or by written communication sent by
                first-class mail to a user’s address in our records. ILoveItCamping.com may, within its sole discretion,
                terminate authorization of users to its Website who are repeat infringers.
              </p>

              <h3>Contact Us</h3>

              <p>
                If you have any questions about this legal notice, the practices of this Website, or your dealings with
                this Website, please contact us at info@iloveitcamping.com.
              </p>

              <p>ILoveItCamping.com</p>
            </Row>
          </Container>
        </BlogPost>
      </Container>
    </Layout>
  );
};

export default TermsAndConditionsPage;
